import React, { useState, useRef, useEffect } from 'react';

import Header from '../Header/Header';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import ViewportModal from '../UI/ViewportModal/ViewportModal';
import Backdrop from '../UI/Backdrop/Backdrop';
import SideDrawer from '../SideDrawer/SideDrawer';

import ViewportModalContext from '../../context/ViewportModalContext';
import NotificationContext from '../../context/NotificationContext';

import LocationSensor from '../LocationSensor';

import classes from './Layout.module.css';

const Layout = ({
    children
}) => {
    const drawerToggleRef = useRef();

    const [showMobileNav, setShowMobileNav] = useState(false);
    const [viewportModalConfig, setViewportModalConfig] = useState(null);
    const [notification, setNotification] = useState(null);
    const [lastFocused, setLastFocused] = useState(null);

    useEffect(() => {
        if (viewportModalConfig === null) {
            lastFocused && lastFocused.focus();
            setLastFocused(null);
        }
    }, [viewportModalConfig, lastFocused]);

    const toggleShowMobileNav = () => {
        setShowMobileNav((show) => !show);
        focusDrawerToggle();
    }

    const closeSideDrawer = () => {
        setShowMobileNav(false);
        drawerToggleRef.current.focus();
    }

    const focusDrawerToggle = () => {
        drawerToggleRef.current.focus();
    }

    const displayViewportModal = (modalContent, handleBackdropClicked) => {
        setViewportModalConfig({ content: modalContent, backdropClickedHandler: handleBackdropClicked });
    }

    const rememberLastFocused = (target) => {
        setLastFocused(target);
    }

    const closeViewportModal = () => {
        setViewportModalConfig(null);
    }

    const showNotification = (notificationMessage, notificationType) => {
        setNotification({ message: notificationMessage, type: notificationType});
    }

    const notificationClasses = [classes.Notification];
    const mainFooterWrapperClasses = [classes.MainFooterWrapper];
    if (showMobileNav) {
        mainFooterWrapperClasses.push(classes.SlideLeft);
    }
    let icon;
    if (notification && notification.type === 'success') {
        notificationClasses.push(classes.NotificationSuccess);
        mainFooterWrapperClasses.push(classes.NotificationDisplayed);
        icon =  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"/>
                </svg>;
    }
    else if (notification && notification.type === 'error') {
        notificationClasses.push(classes.NotificationError);
        mainFooterWrapperClasses.push(classes.NotificationDisplayed);
        icon =  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M16.142 2l5.858 5.858v8.284l-5.858 5.858h-8.284l-5.858-5.858v-8.284l5.858-5.858h8.284zm.829-2h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-8.482 16.992l3.518-3.568 3.554 3.521 1.431-1.43-3.566-3.523 3.535-3.568-1.431-1.432-3.539 3.583-3.581-3.457-1.418 1.418 3.585 3.473-3.507 3.566 1.419 1.417z"/>
                </svg>;
    }

    return (
        <ViewportModalContext.Provider value={{ displayViewportModal, closeViewportModal, rememberLastFocused }}>
            <NotificationContext.Provider value={{ showNotification, notification }}>
                <LocationSensor />
                <Header
                    ref={drawerToggleRef}
                    drawerToggleClicked={toggleShowMobileNav}
                    showSideDrawer={showMobileNav}
                />
                {
                    notification &&
                    <div className={notificationClasses.join(' ')}>
                        <div className={classes.NotificationMessage}>
                            <div className={classes.IconContainer}>
                                {icon}
                            </div>
                            <span>
                                {notification.message}
                            </span>
                        </div>
                        <button
                            onClick={() => setNotification(null)}
                            aria-label='Notification close button.  Use this button to close this notification.'
                        >
                            <svg
                                version="1.1"
                                id="Capa_1"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 47.971 47.971"
                            >
                                <g>
                                	<path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                                		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                                		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                                		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </button>
                    </div>
                }
                <SideDrawer
                    ref={drawerToggleRef}
                    show={showMobileNav}
                    close={closeSideDrawer}
                    focusToggle={focusDrawerToggle}
                />
                <div className={mainFooterWrapperClasses.join(' ')}>
                    {
                        showMobileNav &&
                        <Backdrop
                            clicked={closeSideDrawer}
                            zLevel='250'
                            position='absolute'
                        />
                    }
                    <Main>
                        {children}
                    </Main>
                    <Footer />
                </div>
                {
                    viewportModalConfig &&
                    <ViewportModal
                        backdropClicked={viewportModalConfig.backdropClickedHandler}
                        content={viewportModalConfig.content}
                        zLevel={400}
                    />
                }
            </NotificationContext.Provider>
        </ViewportModalContext.Provider>
    )
}

export default Layout;
