import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import classes from './Logo.module.css';

//204.29 x 224

function Logo({
    imgPath,
    height,
    padding,
    linkToRoute = true,
    backgroundGradient = false
}) {
    const {pathname} = useLocation();

    return (
        <div
            className={classes.Logo}
            style={{height: height, padding: padding}}
        >
            {
                linkToRoute
                ?   <Link
                        to='/'
                        className={(backgroundGradient ? classes.BackgroundGradient : null)}
                        aria-current={(pathname === '/') ? 'page' : null}
                        aria-label={'Link.  The Patient Warrior and Me logo - use this link to go to our home page'}
                    >
                        <img
                            src={imgPath}
                            alt='The Patient Warrior and Me logo'
                        />
                    </Link>
                :   <img
                        src={imgPath}
                        alt='The Patient Warrior and Me logo'
                    />
            }
        </div>
    )
}

export default Logo;
