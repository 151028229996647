import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useSearchParams, createSearchParams } from 'react-router-dom';

import NotificationContext from '../../context/NotificationContext';

import PageIntro from '../../components/PageIntro/PageIntro';
import ResourceCard from '../../components/ResourceCard/ResourceCard';
import Button from '../../components/UI/Button/Button';

import otherResourcesData from '../../data/other-resources.json';
import resources from '../../data/resources.json';

import classes from './OtherResources.module.css';

const OtherResources = () => {
    const { notification } = useContext(NotificationContext);

    const [searchParams, setSearchParams] = useSearchParams({});

    const [resourcesToDisplayQuantityLimit, setResourcesToDisplayQuantityLimit] = useState(8);

    const RESOURCE_EPISODE_QUANTITY_INCREMENT = 8;

    const userCategory = searchParams.get('userCategory');

    const resourcesToDisplay = Object.values(resources).filter((resource) => resource.userCategory === userCategory).slice(0, resourcesToDisplayQuantityLimit);

    useEffect(() => {
        if (!searchParams.get('userCategory')) {
            setSearchParams({ userCategory: 'patient' });
        }
    }, [searchParams, setSearchParams]);

    const pageIntroContent = (
        <span className={classes.IntroContent}>
            {otherResourcesData.pageIntro}
        </span>
    );

    return (
        <>
            <PageIntro
                headingText={otherResourcesData.pageHeading}
                introContent={pageIntroContent}
                logoImageSrc={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                logoImageAlt='The Patient Warrior and Me logo'
            />
            <div className={classes.OtherResources}>
                <div className={[classes.ContentNavPanel, (notification ? classes.WithNotification : classes.WithoutNotification)].join(' ')}>
                    <ul>
                        {
                            Object.keys(otherResourcesData.resources.navPanel.categories).map((categoryKey, index) => {
                                const categoryConfig = otherResourcesData.resources.navPanel.categories[categoryKey];
                                return (
                                    <li
                                        key={index}
                                        className={classes.CategoryContainer}
                                    >
                                        <b>{categoryConfig.heading}</b>
                                        <nav>
                                            <ul>
                                                {
                                                    categoryConfig.types.map((type, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <NavLink
                                                                    to={{ pathname: '/other-resources', search: `?${createSearchParams({userCategory: type.name})}`}}
                                                                    className={(searchParams.get(categoryKey) === type.name) ? classes.active : null}
                                                                    aria-current={(searchParams.get(categoryKey) === type.name) ? 'page' : null}
                                                                    aria-label={`Display ${type.name} resources link.  Use this link to display ${type.name} resources`}
                                                                >
                                                                    {type.linkName}
                                                                </NavLink>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </nav>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className={classes.Content}>
                    {
                        (userCategory && resourcesToDisplay.length !== 0)
                            ?   <>
                                    <h2>{`${userCategory.charAt(0).toUpperCase()}${userCategory.slice(1)} Resources`}</h2>
                                    <ul>
                                        {
                                            resourcesToDisplay.map((resource, index) => {
                                                return  (
                                                    <li key={index}>
                                                        <ResourceCard
                                                            title={resource.title}
                                                            description={resource.description}
                                                            userCategory={resource.userCategory}
                                                            docxFileName={resource.docxFileName}
                                                            pdfFileName={resource.pdfFileName}
                                                            resourceUrl={resource.resourceUrl}
                                                        />
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    {
                                        (resourcesToDisplayQuantityLimit < Object.values(resources).filter((resource) => resource.userCategory === userCategory).length) &&
                                        <Button
                                            types={['large']}
                                            clicked={() => setResourcesToDisplayQuantityLimit((resourcesToDisplayQuantityLimit) => resourcesToDisplayQuantityLimit + RESOURCE_EPISODE_QUANTITY_INCREMENT)}
                                            screenReaderText='Load more button.  Use this button to load more resources.'
                                        >
                                            {otherResourcesData.resources.buttonText}
                                        </Button>
                                    }
                                </>
                            :   <span className={classes.NoResourcesMessage}>
                                    No Warrior Resources found for&nbsp;
                                    <span className={classes.NoResourcesMessageSearchParam}>
                                        "{userCategory}"
                                    </span>
                                </span>
                    }
                </div>
            </div>
        </>
    );
}

export default OtherResources;
