import React, { useEffect, useState } from 'react';

import axios from 'axios';
import XMLParser from 'react-xml-parser';

import classes from './SiteMap.module.css';

const SiteMap = () => {
    const [urls, setUrls] = useState([]);
    const CURRENT_HOSTNAME = window.location.hostname;
    const CURRENT_HREF = window.location.href;
    const CURRENT_HREF_BASE_LENGTH = CURRENT_HREF.indexOf(CURRENT_HOSTNAME) + CURRENT_HOSTNAME.length;
    const CURRENT_HREF_BASE = CURRENT_HREF.substring(0, CURRENT_HREF_BASE_LENGTH);

    useEffect(() => {
        const mappedUrls = [];

        async function mapNestedUrls(pathParts) {
            let urlArray = mappedUrls;
            let fullUrl = CURRENT_HREF_BASE;
            for(const pathPart of pathParts) {
                const foundMappedUrl = urlArray.find(url => url.path === pathPart);
                if (foundMappedUrl) {
                    fullUrl = fullUrl + pathPart;
                    urlArray = foundMappedUrl.nestedLocations;
                }
                else {
                    const pathPartIndex = pathParts.indexOf(pathPart);
                    const pathPartsForNestedUrl = pathParts.slice(pathPartIndex);
                    const nestedUrl = createNestedUrl(pathPartsForNestedUrl, fullUrl);
                    urlArray.push(nestedUrl);
                    break;
                }
            }
        }

        async function mapUrl (path) {
            const pathParts = path.split(/(?=\/)/);
            if (pathParts.length > 1) {
                mapNestedUrls(pathParts);
            }
            else {
                const singlePathPart = pathParts[0];
                const foundMappedUrl = mappedUrls.find(url => url.path === singlePathPart);
                if (!foundMappedUrl) {
                    const singleUrl = CURRENT_HREF_BASE + singlePathPart;
                    mappedUrls.push(createSiteMapItem(singlePathPart, singleUrl));
                }
            }
        }

        const createNestedUrl = (pathPartsToMap, startingBaseUrl) => {
            let fullUrl = startingBaseUrl;
            let nestedUrl = null;
            pathPartsToMap.reverse();
            pathPartsToMap.forEach(pathPart => {
                const pathPartIndex = pathPartsToMap.indexOf(pathPart);
                const pathPreceding = pathPartsToMap.slice(pathPartIndex + 1).reverse().join('');
                fullUrl = startingBaseUrl + pathPreceding + pathPart;
                const basePathWithoutLeadingSlash = pathPart.substring(1);
                const locationName = basePathWithoutLeadingSlash.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                if (nestedUrl) {
                    const nestedLocations = [nestedUrl];
                    const urlToNest = { name: locationName, path: pathPart, location: fullUrl, nestedLocations: nestedLocations };
                    nestedUrl = urlToNest;
                }
                else {
                    nestedUrl = { name: locationName, path: pathPart, location: fullUrl, nestedLocations: [] };
                }
            });
            return nestedUrl;
        }

        const createSiteMapItem = (path, fullUrl) => {
            const basePathWithoutLeadingSlash = path.substring(1);
            const locationName = basePathWithoutLeadingSlash.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return { name: locationName, path: path, location: fullUrl, nestedLocations: [] };
        }

        const groupUrls = (urls) => {
            const CURRENT_HREF_BASE_LENGTH = CURRENT_HREF_BASE.length;
            urls.forEach(url => {
                const locationElement = url.children.find(element => element.name === 'loc');
                const locationValue = locationElement.value;
                const routePath = locationValue.substring(CURRENT_HREF_BASE_LENGTH);
                if (routePath.startsWith('/')) {
                    mapUrl(routePath).then(() => {});
                }
                else {
                    mappedUrls.push({ name: 'Home', path: '/', location: locationValue, nestedLocations: [] });
                }
            })
            setUrls(mappedUrls);
        }

        let siteMapFilePath;
        if (CURRENT_HREF_BASE.endsWith('.net')) {
            siteMapFilePath = './net-sitemap.xml';
        }
        else if (CURRENT_HREF_BASE.endsWith('.org')) {
            siteMapFilePath = './org-sitemap.xml';
        }
        else {
            siteMapFilePath = './sitemap.xml';
        }
        axios.get(siteMapFilePath, {
            "Content-Type": "application/xml; charset=utf-8"
        })
        .then(response => {
            const urlList = new XMLParser().parseFromString(response.data).getElementsByTagName('url');
            groupUrls(urlList);
        });
    }, [CURRENT_HREF_BASE]);

    const generateSiteMapList = (mappedUrls) => {
        return (
            <ul className={classes.SiteMapList}>
                {mappedUrls.map((mappedUrl, index) => {
                    return (
                        <li className={classes.SiteMapListItem} key={index}>
                            <pre><a href={mappedUrl.location}>{mappedUrl.name}</a></pre>
                            {
                                mappedUrl.nestedLocations.length > 0 && generateSiteMapList(mappedUrl.nestedLocations)
                            }
                        </li>
                    );
                })}
            </ul>
        )
    }

    return (
        <div
            className={classes.SiteMap}
        >
            <h1>Site Map</h1>
            <div
                className={classes.LinkContainer}
                style={ {backgroundImage: `url(${process.env.PUBLIC_URL + '/images/other/sitemap.jpg'})`}}
            >
                {
                    generateSiteMapList(urls)
                }
            </div>
        </div>
    );
}

export default SiteMap;
