import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import ViewportModalContext from '../../context/ViewportModalContext';

import Logo from '../UI/Logo/Logo';
import Button from '../UI/Button/Button';
import ContactForm from '../ContactForm/ContactForm';

import footerData from '../../data/footer.json';

import classes from './Footer.module.css';

import routeConfig from '../../data/routes';

const Footer = () => {
    const { rememberLastFocused, displayViewportModal, closeViewportModal } = useContext(ViewportModalContext);
    const location = useLocation();

    const currentYearCopyrightStr = `\u00A9 ${new Date().getFullYear()} The Patient Warrior and Me. All rights reserved.`;

    return (
        <footer>
            <div className={classes.Company}>
                <div className={classes.LogoContainer}>
                    <Logo
                        imgPath={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                        height='100%'
                        padding='0'
                        backgroundGradient={true}
                    />
                </div>
                <div className={classes.CompanyDetails}>
                    <i>{footerData.mission}</i>
                    <b>{footerData.companyName}</b>
                    {
                        Object.keys(footerData.contact).map((contactKey, index) => {
                            const contactConfig = footerData.contact[contactKey];
                            return (
                                <div
                                    key={index}
                                    className={classes.ContactInfo}
                                    >
                                    <img
                                        src={`${process.env.PUBLIC_URL}${contactConfig.iconSrc}`}
                                        alt={contactConfig.iconAltText}
                                    />
                                    <a
                                        href={contactConfig.href}
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        aria-label={contactConfig.spokenText}
                                    >
                                        {contactConfig.text}
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className={classes.CallToAction}>
                <span>{footerData.callToAction.segway}</span>
                <Button
                    types={['medium']}
                    clicked={(event) => {
                        rememberLastFocused(event.target);
                        displayViewportModal(<ContactForm contactReason='contactUs' introText='How can we be of assistance?' />, () => closeViewportModal());
                    }}
                    screenReaderText={footerData.callToAction.spokenText}
                >
                    {footerData.callToAction.text}
                </Button>
                <ul className={classes.SocialMediaLinks}>
                    <li>
                        <a
                            href='https://www.facebook.com/profile.php?id=100087050580706'
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label='Link to The Patient Warrior and Me Facebook page'
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
            <nav className={classes.MainLinks}>
                <ul>
                    {
                        Object.keys(routeConfig.mainRoutes).map((routeKey, index) => {
                            const routeItemConfig = routeConfig.mainRoutes[routeKey];
                            let content;
                            if (routeItemConfig.path !== '/') {
                                content = (
                                    <li key={index}>
                                        <NavLink
                                            to={routeItemConfig.path}
                                            className={navData => navData.isActive ? classes.active : ''}
                                            aria-current={(location.pathname.includes(routeItemConfig.path)) ? 'page' : null}
                                        >
                                            {routeItemConfig.viewName}
                                        </NavLink>
                                        {
                                            (index < Object.keys(routeConfig.mainRoutes).length - 1) &&
                                            <span className={classes.MainRouteLinkItemDivider}>|</span>
                                        }
                                    </li>
                                );
                            }
                            return content;
                        })
                    }
                </ul>
            </nav>
            <nav className={classes.OtherLnks}>
                <ul>
                    {
                        Object.keys(routeConfig.otherRoutes).map((routeKey, index) => {
                            const routeItemConfig = routeConfig.otherRoutes[routeKey];
                            return (
                                <li key={index}>
                                    <NavLink
                                        to={routeItemConfig.path}
                                        className={navData => navData.isActive ? classes.active : ''}
                                        aria-current={(location.pathname.includes(routeItemConfig.path)) ? 'page' : null}
                                    >
                                        {routeItemConfig.viewName}
                                    </NavLink>
                                    {
                                        (index < Object.keys(routeConfig.otherRoutes).length - 1) &&
                                        <span className={classes.LinkItemDivider}>|</span>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
            <div className={classes.Mentions}>
                <span className={classes.OwnerMention}>{currentYearCopyrightStr}</span>
                <span className={classes.WebDesignerMention}>
                    <i>Powered by</i>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/company logos/transparent-magnet-web-designs-logo.png`}
                        alt='Logo for Magnet Web Designs LLC'
                    />
                    <a
                        href='http://www.magnetwebdesigns.com'
                        target= "_blank"
                        rel="noopener noreferrer"
                        aria-label='Link to website for Magnet Web Designs'
                    >
                        Magnet Web Designs
                    </a>
                </span>
            </div>
        </footer>
    )
}

export default Footer;