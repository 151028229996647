import React from 'react';

import classes from './Backdrop.module.css';

const Backdrop = ({
    clicked,
    positioning = 'fixed',
    zLevel
}) => {
    let backdropClasses = [classes.Backdrop];
    if (positioning === 'fixed') {
        backdropClasses.push(classes.Fixed);
    }
    else if (positioning === 'absolute') {
        backdropClasses.push(classes.Absolute);
    }
    return (
        <div
            className={backdropClasses.join(' ')}
            style={ {zIndex: zLevel} }
            onClick={clicked}
        >
        </div>
    );
}

export default Backdrop;
