import React from 'react';

import TextLink from '../../components/UI/TextLink/TextLink';
import classes from './NotFound.module.css';

const NotFound = () => {

    return (
        <div className={classes.NotFound}>
            <div className={classes.LogoImage}>
            <img
                src={`${process.env.PUBLIC_URL}/images/other/page-not-found.jpg`}
                alt='Patient Warrior and Me logo'
            />
            </div>
            <div className={classes.Content}>
                <h1>Page Not Found</h1>
                <p>We're sorry but the page you were looking for does not exist.</p>
                <p>For a fresh start, visit our <TextLink destination='/'>Home</TextLink> page.</p>
                <p>For helpful resources for patients and caregivers, check out our <TextLink destination='/other-resources'>Other Resources</TextLink> page.</p>
                <p>Our <TextLink destination='/podcasts'>Podcasts</TextLink> page lists all of our podcast episodes.</p>
                <p>To learn more about The Patient Warrior and Me, visit our <TextLink destination='/about'>About</TextLink> page.</p>
            </div>
        </div>
    );
}

export default NotFound;
