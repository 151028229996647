import React, { forwardRef } from 'react';

import classes from './Button.module.css';

const Button = forwardRef((
    {
        elementId,
        types,
        italicFont = false,
        interactive = true,
        clicked = () => {},
        screenReaderText = '',
        children
    }, buttonRef) => {

    const buttonClasses = [classes.Button];

    if (!types) {
        buttonClasses.push(classes.Small);
    }
    else {
        if (types.includes('large')) {
            buttonClasses.push(classes.Large);
        }
        if (types.includes('medium')) {
            buttonClasses.push(classes.Medium);
        }
        else {
            buttonClasses.push(classes.Small);
        }
    }

    return (
        <button
            id={elementId}
            ref={buttonRef}
            className={buttonClasses.join(' ')}
            onClick={clicked}
            aria-label={screenReaderText}
            style={{ fontStyle: (italicFont ? 'italic' : 'normal')}}
            disabled={!interactive}
        >
            {children}
        </button>
    );
});

export default Button;
