import React, { forwardRef } from 'react';

import Logo from '../UI/Logo/Logo';
import NavigationItems from '../UI/NavigationItems/NavigationItems';
import DrawerToggle from '../UI/DrawerToggle/DrawerToggle';

import classes from './Header.module.css';

import routeConfig from '../../data/routes';

const Header = forwardRef(({
    showSideDrawer,
    drawerToggleClicked
}, drawerToggleRef) => {

    return (
        <header id='site-header'>
            <Logo
                imgPath={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo-without-company-name.png`}
                height='100%'
                padding='0'
                backgroundGradient={true}
            />
            <span className={classes.Title}>The Patient Warrior and Me</span>
            <nav className={classes.DesktopOnly}>
                <NavigationItems routes={routeConfig.mainRoutes} />
            </nav>
            <DrawerToggle
                ref={drawerToggleRef}
                sideDrawerOpen={showSideDrawer}
                clicked={drawerToggleClicked}
            />
        </header>
    )
});

export default Header;
