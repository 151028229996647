import React from 'react';

import Logo from '../UI/Logo/Logo';

import classes from './PageIntro.module.css';

const PageIntro = ({
    headingText,
    introContent,
    logoImageSrc,
    logoImageAlt
}) => {

    return (
        <div className={classes.PageIntro}>
            <h1>{headingText}</h1>
            <div className={classes.IntroContent}>
                {introContent}
            </div>
            <div className={classes.Tagline}>
                <span>
                    Encouraging.
                </span>
                <span>
                    Educating.
                </span>
                <span>
                    Empowering.
                </span>
            </div>
            <div className={classes.LogoImage}>
                <Logo
                    imgPath={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                    linkToRoute={false}
                    height='100%'
                    padding='0'
                />
            </div>
        </div>
    );
}

export default PageIntro;
