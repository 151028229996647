import React, { useState, useContext } from 'react';

import PodcastEpisodeCard from '../../components/PodcastEpisodeCard/PodcastEpisodeCard';
import ResourceCard from '../../components/ResourceCard/ResourceCard';
import Button from '../../components/UI/Button/Button';
import StyledLink from '../../components/UI/StyledLink/StyledLink';
import TextLink from '../../components/UI/TextLink/TextLink';
import ContactForm from '../../components/ContactForm/ContactForm';

import ViewportModalContext from '../../context/ViewportModalContext';

import homeData from '../../data/home.json';
import podcastEpisodeData from '../../data/podcast-episodes.json';
import resources from '../../data/resources.json';
import introVideoOgg from '../../assets/videos/home-intro.ogg';
import introVideoMp4 from '../../assets/videos/home-intro.mp4';

import classes from './Home.module.css';

const Home = () => {
    const { rememberLastFocused, displayViewportModal, closeViewportModal } = useContext(ViewportModalContext);

    const [showIntroVideoOverlay, setShowIntroVideoOverlay] = useState(true);

    const podcastDataQuantity = Object.keys(podcastEpisodeData).length;
    const resourcesQuantity = Object.keys(resources).length;

    const handleIntroVideoOverlayClicked = () => {
        setShowIntroVideoOverlay(false);
        const introVideo = document.getElementById('intro-video');
        introVideo.play();
    }

  return (
      <>
        <div className={classes.TopPanel}>
            <h1>The Patient Warrior and Me</h1>
            <div
                className={classes.IntroVideo}
            >
                <video
                    id='intro-video'
                    // poster={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                    controls={!showIntroVideoOverlay}
                >
                    <source
                        src={introVideoMp4}
                        type="video/mp4"
                    />
                    <source
                        src={introVideoOgg}
                        type="video/ogg"
                    />
                    <span style={ {color: 'var(--red-color-hex)'} }>Your browser does not support HTML5 video</span>
                </video>
                <div
                    className={[classes.Overlay, (!showIntroVideoOverlay ? classes.Hide : null)].join(' ')}
                    style={ {backgroundImage: `url(${process.env.PUBLIC_URL}'/images/video-cover-images/home-intro-capture.png')`}}
                    onClick={() => handleIntroVideoOverlayClicked()}
                >
                    <div className={classes.Mask}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <span className={classes.Motto}>
                {homeData.motto}
            </span>
            <img
                className={classes.LogoImage}
                src={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                alt='The Patient Warrior and Me logo'
            />
        </div>
        <div className={classes.Content}>
            <div className={classes.ServiceSummary}>
                {homeData.serviceSummary}
            </div>
            <div className={classes.PodcastReleases}>
                <h2>{homeData.podcasts.headingText}</h2>
                <ul>
                    {
                        Object.keys(podcastEpisodeData)
                            .slice(podcastDataQuantity - 2, podcastDataQuantity)
                            .reverse()
                            .map((releaseKey, index) => {
                                const episodeDetails = podcastEpisodeData[releaseKey];
                                return (
                                    <li key={index}>
                                        <PodcastEpisodeCard
                                            coverImageFileName={episodeDetails.imageFileName}
                                            runTime={episodeDetails.runtime}
                                            ordinalNumber={releaseKey}
                                            recordDate={episodeDetails.recordDate}
                                            title={episodeDetails.title}
                                            description={episodeDetails.description}
                                            spotifyUrl={episodeDetails.spotifyUrl}
                                            resources={episodeDetails.resources}
                                        />
                                    </li>
                                );
                            }
                        )
                    }
                </ul>
                <p>
                    {homeData.podcasts.trailingText}
                </p>
                <TextLink
                    destination='/podcasts'
                    screenReaderText='Browse our podcast episodes link.  Use this link to go to our Podcasts page and browse all of our podcast episodes.'
                >
                    {homeData.podcasts.associatedViewLinkText}
                </TextLink>
            </div>
            <hr className={classes.Divider} />
            <div className={classes.Resources}>
                <h2>{homeData.resources.headingText}</h2>
                <ul>
                    {
                        Object.values(resources)
                            .slice(resourcesQuantity - 1, resourcesQuantity)
                            .reverse()
                            .map((resource, index) =>
                            (
                                <li key={index}>
                                    <ResourceCard
                                        title={resource.title}
                                        description={resource.description}
                                        userCategory={resource.userCategory}
                                        docxFileName={resource.docxFileName}
                                        pdfFileName={resource.pdfFileName}
                                        resourceUrl={resource.resourceUrl}
                                    />
                                </li>
                            )
                        )
                    }
                </ul>
                <p>
                    {homeData.resources.trailingText}
                </p>
                <TextLink
                    destination='/other-resources'
                    screenReaderText='Browse warrior resources link.  Use this link to go to our Other Resources page and browse all of our warrior resources.'
                >
                    {homeData.resources.associatedViewLinkText}
                </TextLink>
            </div>
            <p className={classes.AffiliateText}>
                {homeData.affiliates.headingText}
            </p>
            <div className={classes.AffiliateLinks}>
                <StyledLink
                    destination='about#partners'
                    screenReaderText='Check out our partners link.  Use this link to go to the About page to learn more about our partners.'
                >
                    {homeData.affiliates.partnersLinkText}
                </StyledLink>
                <Button
                    clicked={(event) => {
                        rememberLastFocused(event.target);
                        displayViewportModal(<ContactForm contactReason='sponsor' introText='Thank you for your interest in becoming a sponsor.' />, () => closeViewportModal());
                    }}
                    screenReaderText='Sponsor interest form button.  Use this button to display the sponsor interest form.'
                >
                    {homeData.affiliates.sponsorsLinkText}
                </Button>
            </div>
        </div>
      </>
  );
}

export default Home;
