import React from 'react';

import classes from './TermsOfService.module.css';

const TermsOfService = ( props ) => {

    return (
        <div className={classes.TermsOfService}>
            <h1>Website Terms of Service</h1>
            <h2>Agreement between the User and thepatientwarriorandme.com</h2>
            <p>Welcome to thepatientwarriorandme.com. The thepatientwarriorandme.com website (the "Site") is comprised of various web pages operated by The Patient Warrior and Me LLC ("The Patient Warrior and Me").
            thepatientwarriorandme.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein
            (the "Terms"). Your use of thepatientwarriorandme.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of
            them for your reference.</p>
            <p>thepatientwarriorandme.com is a Vacation Home Rental Site.  thepatientwarriorandme.com showcases the services offered to those looking to book vacation rental property as well as services offered
             to homeowners of vacation rental property.  Vacation Rental property details are available on the Site along with links to book the properties.</p>
            <h2>Privacy</h2>
            <p>Your use of thepatientwarriorandme.com is subject to The Patient Warrior and Me's Privacy Policy. Please review our Privacy Policy, which also governs the Site and
            informs users of our data collection practices.</p>
            <h2>Accessibility</h2>
            <p>The Patient Warrior and Me LLC is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience
            for everyone, and applying the relevant accessibility standards.</p>
            <p>We welcome your feedback on the accessibility of thepatientwarriorandme.com. Please let us know if you encounter accessibility barriers on
            thepatientwarriorandme.com by messaging us via our Contact Us Form or by using one of the contact methods listed at the end of these Terms.</p>
            <h2>Electronic Communications</h2>
            <p>Visiting thepatientwarriorandme.com or sending emails to The Patient Warrior and Me constitutes electronic communications. You consent to receive electronic communications
            and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site,
            satisfy any legal requirement that such communications be in writing.</p>
            <h2>Children Under Thirteen</h2>
            <p>The Patient Warrior and Me does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under
            18, you may use thepatientwarriorandme.com only with permission of a parent or guardian.</p>
            <h2>Links to Third Party Sites/Third Party Services</h2>
            <p>thepatientwarriorandme.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of The Patient Warrior and Me and The Patient Warrior and Me
            is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or
            updates to a Linked Site. The Patient Warrior and Me is providing these links to you only as a convenience, and the inclusion of any link does not imply
            endorsement by The Patient Warrior and Me of the site or any association with its operators.</p>
            <p>Certain services made available via thepatientwarriorandme.com are delivered by third party sites and organizations. By using any product, service or
            functionality originating from the thepatientwarriorandme.com domain, you hereby acknowledge and consent that The Patient Warrior and Me may share such information and
            data with any third party with whom The Patient Warrior and Me has a contractual relationship to provide the requested product, service or functionality on
            behalf of thepatientwarriorandme.com users and customers.</p>
            <h2>No Unlawful or Prohibited Use/Intellectual Property</h2>
            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use thepatientwarriorandme.com strictly in accordance with these terms
            of use. As a condition of your use of the Site, you warrant to The Patient Warrior and Me that you will not use the Site for any purpose that is unlawful or
            prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with
            any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not
            intentionally made available or provided for through the Site.</p>
            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used
            on the Site, is the property of The Patient Warrior and Me or its suppliers and protected by copyright and other laws that protect intellectual property and
            proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in
            any such content and will not make any changes thereto.</p>
            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit
            any of the content, in whole or in part, found on the Site. The Patient Warrior and Me content is not for resale. Your use of the Site does not entitle you to
            make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices
            in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written
            permission of The Patient Warrior and Me and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not
            grant you any licenses, express or implied, to the intellectual property of The Patient Warrior and Me or our licensors except as expressly authorized by
            these Terms.</p>
            <h2>International Users</h2>
            <p>The Service is controlled, operated and administered by The Patient Warrior and Me from our offices within the USA. If you access the Service from a location
            outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the The Patient Warrior and Me Content accessed through
            thepatientwarriorandme.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
            <h2>Indemnification</h2>
            <p>You agree to indemnify, defend and hold harmless The Patient Warrior and Me, its officers, directors, employees, agents and third parties, for any losses,
            costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site
            or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party,
            or your violation of any applicable laws, rules or regulations. The Patient Warrior and Me reserves the right, at its own cost, to assume the exclusive
            defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with The Patient Warrior and Me in
            asserting any available defenses.</p>
            <h2>Liability Disclaimer</h2>
            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. THE PATIENT WARRIOR AND ME LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES
            IN THE SITE AT ANY TIME.</p>
            <p>THE PATIENT WARRIOR AND ME LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
            OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
            BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION
            OF ANY KIND. THE PATIENT WARRIOR AND ME LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE AND NON-INFRINGEMENT.</p>
            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE PATIENT WARRIOR AND ME LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
            PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
            PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
            SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED
            THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            EVEN IF THE PATIENT WARRIOR AND ME LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
            THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
            DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
            <h2>Termination/Access Restriction</h2>
            <p>The Patient Warrior and Me LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Arizona and you
            hereby consent to the exclusive jurisdiction and venue of courts in Arizona in all disputes arising out of or relating to the use of the Site.
            Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation,
            this section.</p>
            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and The Patient Warrior and Me as a result of this
            agreement or use of the Site. The Patient Warrior and Me's performance of this agreement is subject to existing laws and legal process, and nothing contained
            in this agreement is in derogation of The Patient Warrior and Me's right to comply with governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or gathered by The Patient Warrior and Me with respect to such use. If any part of this agreement
            is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability
            limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most
            closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and The Patient Warrior and Me with respect to the Site
            and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and The Patient Warrior and Me
            with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial
            or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all
            related documents be written in English.</p>
            <h2>Changes to Terms</h2>
            <p>The Patient Warrior and Me reserves the right, in its sole discretion, to change the Terms under which thepatientwarriorandme.com is offered. The most current version
            of the Terms will supersede all previous versions. The Patient Warrior and Me encourages you to periodically review the Terms to stay informed of our updates.</p>
            <h2>Contact Us</h2>
            <span>The Patient Warrior and Me welcomes your questions or comments regarding the Terms:</span>
            <div className={classes.ContactBlock}>
                <b>The Patient Warrior and Me LLC</b>
                <span>4745 S Burma Rd</span>
                <span>Gilbert, AZ  85297</span>
            </div>
            <div className={classes.ContactBlock}>
                <b>Email Address:</b>
                <span>thepatientwarriorandme@gmail.com</span>
            </div>
            <div className={classes.ContactBlock}>
                <b>Telephone number:</b>
                <span>(860) 716-7021</span>
            </div>
            <b><i>Effective as of July 3, 2022</i></b>
        </div>
    );
}

export default TermsOfService;
