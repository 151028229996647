import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './StyledLink.module.css';

const StyledLink = (
    {
        destination,
        types,
        italicFont = false,
        clicked = () => {},
        screenReaderText = '',
        children
    }) => {

    const linkClasses = [classes.StyledLink];

    if (!types) {
        linkClasses.push(classes.Small);
    }
    else {
        if (types.includes('large')) {
            linkClasses.push(classes.Large);
        }
        if (types.includes('medium')) {
            linkClasses.push(classes.Medium);
        }
        else {
            linkClasses.push(classes.Small);
        }
    }

    return (
        <NavLink
            className={linkClasses.join(' ')}
            to={destination}
            onClick={clicked}
            aria-label={screenReaderText}
            style={{ fontStyle: (italicFont ? 'italic' : 'normal')}}
        >
            {children}
        </NavLink>
    );
};

export default StyledLink;
