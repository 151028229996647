import React from 'react';

import classes from './Input.module.css';

const Input = ({
    id,
    name,
    label,
    elementType,
    elementConfig,
    inputRef,
    keyDown,
    changed,
    clicked,
    blurred,
    validationRules,
    errMessage,
    screenReaderText
}) => {
    let isRequiredInput = null;
    if (validationRules) {
        const requiresValue = (element) => element.required === true;
        isRequiredInput = validationRules.some(requiresValue);
    }

    const inputClasses = [classes.Input];

    if (errMessage) {
        inputClasses.push(classes.Error);
    }

    let inputElement;
    switch( elementType ) {
        case ( 'text' ):
            inputElement =
                <input
                    id={id}
                    name={name}
                    {...elementConfig}
                    value={elementConfig.value}
                    ref={inputRef}
                    onChange={changed}
                    onFocus={(event) => event.target.removeAttribute('placeholder')}
                    onBlur={(event) => {event.target.setAttribute('placeholder', elementConfig.placeholder); blurred(event);}}
                    onKeyDown={(event) => keyDown(event)}
                    onClick={clicked}
                    aria-label={screenReaderText}
                    aria-required={isRequiredInput}
                />;
            break;
        case ( 'textarea' ):
            inputElement =
                <textarea
                    id={id}
                    name={name}
                    {...elementConfig}
                    value={elementConfig.value}
                    rows={elementConfig.rows}
                    onChange={changed}
                    onFocus={(event) => event.target.removeAttribute('placeholder')}
                    onBlur={(event) => {event.target.setAttribute('placeholder', elementConfig.placeholder); blurred(event);}}
                    aria-label={screenReaderText}
                    aria-required={isRequiredInput}
                />;
            break;
        default:
            inputElement = null;
    }

    return (
        <div className={inputClasses.join(' ')}>
            <label
                htmlFor={id}
            >
                {label}
                {isRequiredInput
                    ?   <span className={classes.RequiredFieldIndicator} aria-hidden='true'>*</span>
                    :   null
                }
            </label>
            {inputElement}
            <div className={classes.ErrorMessageContainer}>
                {
                    errMessage
                        ?   <>
                                <img
                                    className={classes.WarningIcon}
                                    src={`${process.env.PUBLIC_URL}/images/icons/warning-icon.png`}
                                    alt='warning icon'
                                />
                                <span className={classes.ErrorMessage}>
                                    {errMessage}
                                </span>
                            </>
                        : null
                }
            </div>
        </div>
    );
};

export default Input;
