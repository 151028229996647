import React, { useState, useRef, useEffect } from 'react';

import Button from '../UI/Button/Button';
import classes from './PodcastEpisodeCard.module.css';

function PodcastEpisodeCard({
    coverImageFileName,
    runTime,
    ordinalNumber,
    recordDate,
    title,
    description,
    spotifyUrl,
    resources
}) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const podcastEpisodeDescriptionRef = useRef();
    // const fullDescriptionContainerRef = useRef();

    // const DESCRIPTION_HIDE_OFFSET_HEIGHT = 100;
    const DESCRIPTION_HIDE_OFFSET_HEIGHT = 70;

    const closeFullDescriptionContainer = () => {
        setShowFullDescription(false);
        const descriptionReadMoreButton = document.getElementById(`podcast-description-read-more-${ordinalNumber}`);
        descriptionReadMoreButton.focus();
    }

    useEffect(() => {
        if (podcastEpisodeDescriptionRef.current.offsetHeight > DESCRIPTION_HIDE_OFFSET_HEIGHT) {
            setExpandable(true);
        }
    }, []);

    useEffect(() => {
        if (showFullDescription) {
            const fullDescriptionContainer = document.getElementById(`full-description-container-${ordinalNumber}`);
            fullDescriptionContainer.focus();
        }
        const handleKeyDown = (event) => {
            const fullDescriptionContainer = document.getElementById(`full-description-container-${ordinalNumber}`);
            const descriptionReadMoreButton = document.getElementById(`podcast-description-read-more-${ordinalNumber}`);
            if ( !event.keyCode || event.keyCode === 27 ) {
                event.preventDefault();
                setShowFullDescription(false);
                descriptionReadMoreButton.focus();
            }
            if (event.key === 'Tab' || event.keyCode === 9) {
                if (event.shiftKey) {
                    if (document.activeElement === fullDescriptionContainer) {
                        event.preventDefault();
                        descriptionReadMoreButton.focus();
                    }
                }
            }
        }
        if (showFullDescription) {
            document.addEventListener('keydown', handleKeyDown);

        }
    }, [showFullDescription, ordinalNumber]);

    const descriptionClasses = [classes.Description];

    if (expandable) {
        descriptionClasses.push(classes.ClipDescription);
    }

    return (
        <div
            className={classes.PodcastEpisodeCard}
        >
            <a
                className={classes.CoverImageLink}
                href={spotifyUrl}
                target= "_blank"
                rel="noopener noreferrer"
                aria-label={`Link to the podcast episode titled ${title}.  Use this link to open this podcast episode, in a new browser tab, on our Buzzfeed channel page.`}
            >
                <img
                    src={require(`../../assets/images/podcast-cover-images/${coverImageFileName}`)}
                    alt={`cover for The Patient Warrior and Me podcast episode ${ordinalNumber}`}
                />
                <span className={classes.Runtime}>
                    {runTime}
                </span>
                <div className={classes.Overlay}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // className={classes.PlayImage}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
                    </svg>
                </div>
            </a>
            <section className={classes.RecordedSequenceDetails}>
                <h3>{`Episode ${ordinalNumber}`}</h3>
                <span>{recordDate}</span>
            </section>
            <div className={classes.Summary}>
                <a
                    className={classes.Title}
                    href={spotifyUrl}
                    target= "_blank"
                    rel="noopener noreferrer"
                    aria-label={`Link to the podcast episode titled ${title}.  Use this link to open this podcast episode, in a new browser tab, on our Buzzfeed channel page.`}
                >
                    {title}
                </a>
                <div
                    ref={podcastEpisodeDescriptionRef}
                    className={descriptionClasses.join(' ')}
                >
                    {description}
                    {
                        resources &&
                        <section className={classes.Resources}>
                            <h4>Resources</h4>
                            {
                                resources.map((resource) => (
                                    <ul className={classes.ResourcesList}>
                                        <li>
                                            <a 
                                                href={resource.link}
                                                target='_blank'
                                                rel="noopener noreferrer"
                                                aria-label={resource.spokenText}
                                            >
                                                {resource.title}
                                            </a>
                                        </li>
                                    </ul>
                                ))
                            }
                        </section>
                    }
                    {
                        expandable &&
                        <div className={classes.ContentClip}>
                            <button
                                id={`podcast-description-read-more-${ordinalNumber}`}
                                className={classes.ExpandDescriptionButton}
                                onClick={() => setShowFullDescription(true)}
                                aria-label='Read more button.  Use this button to display the entire description of this podcast episode.'
                            >
                                Read More
                            </button>
                        </div>
                    }
                </div>
                {
                    showFullDescription
                    ?   <div
                            // ref={fullDescriptionContainerRef}
                            id={`full-description-container-${ordinalNumber}`}
                            className={classes.FullDescription}
                            tabIndex='0'
                        >
                            <div>
                                {description}
                                {
                                    resources &&
                                    <section className={classes.Resources}>
                                        <h4>Resources</h4>
                                        {
                                            resources.map((resource) => (
                                                <ul className={classes.ResourcesList}>
                                                    <li>
                                                        <a
                                                            href={resource.link}
                                                            target='_blank'
                                                            rel="noopener noreferrer"
                                                            aria-label={resource.spokenText}
                                                        >
                                                            {resource.title}
                                                        </a>
                                                    </li>
                                                </ul>
                                            ))
                                        }
                                    </section>
                                }
                            </div>
                            <Button
                                types={['extra-small']}
                                clicked={() => closeFullDescriptionContainer()}
                                screenReaderText='Close button.  Use this button to close this podcast episode description.'
                            >
                                Close
                            </Button>
                        </div>
                    :   null
                }
            </div>
            <div className={classes.MobileOnly}>
                <a
                    href='https://www.magnetwebdesigns.com'
                    target= "_blank"
                    rel="noopener noreferrer"
                    aria-label={`Link to the podcast episode titled ${title}.  Use this link to open this podcast episode, in a new browser tab, on our Buzzfeed channel page.`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
                    </svg>
                </a>
                <span>
                    {runTime}
                </span>
            </div>
        </div>
    )
}

export default PodcastEpisodeCard;
