import React from 'react';

import classes from './Spinner.module.css';

const Spinner = ({
    message
}) => {
    return (
        <div className={classes.Spinner}>
            <div className={classes.Animation}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            {
                message &&
                <span className={classes.Message}>
                    {message}
                </span>
            }
        </div>
    );
}

export default Spinner;
