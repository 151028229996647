import React from 'react';

import classes from './Main.module.css';

const Main = ({
    children
}) => {

    return (
        <div className={classes.Main}>
            {children}
        </div>
    )
}

export default Main;
