import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './views/Home/Home';
import Podcasts from './views/Podcasts/Podcasts';
import OtherResources from './views/OtherResources/OtherResources';
import About from './views/About/About';
import SiteMap from './views/SiteMap/SiteMap';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './views/TermsOfService/TermsOfService';
import NotFound from './views/NotFound/NotFound';

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/podcasts" element={<Podcasts />} />
                    <Route path="/other-resources" element={<OtherResources />} >

                    </Route>
                    <Route path="/about" element={<About />} />
                    <Route path="/sitemap" element={<SiteMap />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route index element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}

export default App;
