import React from 'react';

import Backdrop from '../Backdrop/Backdrop';

import classes from './ViewportModal.module.css';

const ViewportModal = ({
    backdropClicked,
    content,
    zLevel
}) => {
    const contentZLevel = zLevel + 25;

    return (
        <div
            className={classes.ViewportModal}
            style={ {zIndex: zLevel} }
        >
            <Backdrop
                clicked={backdropClicked}
            />
            <div
                style={ {zIndex: contentZLevel} }
            >
                {content}
            </div>
        </div>
    );
};

export default ViewportModal;
