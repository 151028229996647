import React from 'react';
import { useLocation } from 'react-router-dom';

import NavigationItem from './NavigationItem/NavigationItem';

import classes from './NavigationItems.module.css';

const NavigationItems = ({
    routes,
    navItemClicked = () => {},
    showNavItems = true
}) => {
    const {pathname} = useLocation();

    return (
        <ul className={classes.NavigationItems}>
            {
                Object.keys(routes).map((routeItemKey, index) => {
                    const routeItemConfig = routes[routeItemKey];
                        return (routeItemConfig.path !== '/')
                        ? (
                            <li
                                key={index}
                            >
                                <NavigationItem
                                    path={routeItemConfig.path}
                                    clicked={navItemClicked}
                                    currentRoute={(pathname.includes(routeItemConfig.path))}
                                    focusable={showNavItems}
                                >
                                    {routeItemConfig.viewName}
                                </NavigationItem>
                            </li>
                        )
                        : null;
                })
            }
        </ul>
    )
}

export default NavigationItems;
