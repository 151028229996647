import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LocationSensor = () => {
    let location = useLocation();

    useEffect(() => {
        const viewConfig =
            {
                home: {
                    basePath: '',
                    viewName: 'Home'
                },
                services: {
                    basePath: 'podcasts',
                    viewName: 'Podcasts'
                },
                portfolio: {
                    basePath: 'other-resources',
                    viewName: 'Other Resources'
                },
                contact: {
                    basePath: 'about',
                    viewName: 'About'
                },
                privacy: {
                    basePath: 'privacy-policy',
                    viewName: 'Privacy Policy'
                },
                terms: {
                    basePath: 'terms-of-service',
                    viewName: 'Terms of Service'
                },
                sitemap: {
                    basePath: 'sitemap',
                    viewName: 'Site Map'
                },
                404: {
                    viewName: '404 Not Found'
                }
            }

        const translateRoutePathToName = (location) => {
            let updatedViewName;

            const pathNameWithoutLeadingSlash = (location.pathname.indexOf('/') === 0)
                ? location.pathname.substring(1)
                : location.pathname;

            let pathArray = pathNameWithoutLeadingSlash.split('/', 1);

            let basePath;
            if (pathArray && pathArray.length > 0) {
                basePath = pathArray[0];
            }

            Object.values(viewConfig).forEach(configItem => {
                if (basePath === configItem.basePath) {
                    updatedViewName = configItem.viewName;
                }
            })
            updatedViewName = (!updatedViewName) ? '404 Not Found' : updatedViewName;
            document.title = `${updatedViewName} - The Patient Warrior and Me`;
        }

        translateRoutePathToName(location);
    }, [location]);

    useEffect(() => {
        const awaitScrollEnd = (callback = () => {}) => {
            let scrollTimeout;
            const awaitScrollStop = (event) => {
                window.clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(function() {
                    scrollTimeout = null;
                    window.clearTimeout(scrollTimeout);
                    window.removeEventListener('scroll', awaitScrollStop);
                    callback();
                }, 400);
            }
            window.addEventListener('scroll', awaitScrollStop, false);
        }

        const focusTargetById = (target, callback = () => {}) => {
            if (target) {
                if (target.tabIndex !== 0) {
                    target.setAttribute('tabindex', '-1');
                    target.focus({ preventScroll: true });
                }
                else {
                    target.focus({ preventScroll: true });
                }
            }
            callback();
        }

        const getDesiredPixelScrollPosition = (scrollElement, topBuffer = 0) => {
            let buffer;
            if (topBuffer === 0) {
                const style = window.getComputedStyle ? getComputedStyle(scrollElement, null) : scrollElement.currentStyle;
                buffer = parseInt(style.marginTop) || 0;
            }
            else {
                buffer = topBuffer;
            }
            const headerHeight = document.getElementById('site-header').offsetHeight;
            console.log('headerHeight ', headerHeight);

            console.log('desiredPixelPos: ', window.pageYOffset + scrollElement.getBoundingClientRect().top - buffer - headerHeight);
            return window.pageYOffset + scrollElement.getBoundingClientRect().top - buffer - headerHeight;
        }

        const scrollAndFocusByElementId = (scrollElementId, focusElementId, topBuffer = 0) => {
            window.setTimeout(() => {

                let scrollElement;
                if (scrollElementId.startsWith('#')) {
                    scrollElementId = scrollElementId.replace('#', '');
                }
                if (scrollElementId) {
                    scrollElement = document.getElementById(scrollElementId);
                }

                let focusElement;
                if (focusElementId.startsWith('#')) {
                    focusElementId = focusElementId.replace('#', '');
                }
                if (focusElementId) {
                    focusElement = document.getElementById(focusElementId);
                }

                if (scrollElement) {
                    const desiredPixelPos = getDesiredPixelScrollPosition(scrollElement, topBuffer);
                    if (focusElement) {
                        awaitScrollEnd(() => {
                            focusTargetById(focusElement);
                        });
                    }
                    window.scrollTo({
                        top: desiredPixelPos,
                        behavior: 'smooth'
                    });

                    console.log('scrolled');
                }
                else if (focusElement) {
                    focusTargetById(focusElement);
                }

            }, 1000);

        }

        if (location.hash && location.state && location.state.focusTargetId) {
            // scroll to one target, focus on a different target
            if (location.state.scrollTargetTopBuffer) {
                scrollAndFocusByElementId(location.hash, location.state.focusTargetId, location.state.scrollTargetTopBuffer);
            }
            else {
                scrollAndFocusByElementId(location.hash, location.state.focusTargetId);
            }
        }
        else if (location.hash) {
            // scroll and focus on the same target
            if (location.state && location.state.scrollTargetTopBuffer) {
                scrollAndFocusByElementId(location.hash, location.hash, location.state.scrollTargetTopBuffer);
            }
            else {
                console.log('started here');
                scrollAndFocusByElementId(location.hash, location.hash);

            }
        }
        else if (location.state && location.state.scrollTo) {
            // scroll to and focus same target after route resolves
            if (location.state.scrollTargetTopBuffer) {
                scrollAndFocusByElementId(location.state.scrollTo, location.state.scrollTo, location.state.scrollTargetTopBuffer);
            }
            else {
                scrollAndFocusByElementId(location.state.scrollTo, location.state.scrollTo);
            }
        }
        else if (location.state && location.state.preventScroll) {
            // avoid scrolling for a change in a nested route
            return;
        }
        else {
            // scroll to top of view on outermost route change
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
}

export default LocationSensor;
