import React, { useEffect, useState, useRef } from 'react';

import classes from './ResourceCard.module.css';

const ResourceCard = ({
    title,
    description,
    userCategory,
    docxFileName,
    pdfFileName,
    resourceUrl
}) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const resourceDescriptionRef = useRef();

    const DESCRIPTION_HIDE_OFFSET_HEIGHT = 80;

    useEffect(() => {
        if (resourceDescriptionRef.current.offsetHeight > DESCRIPTION_HIDE_OFFSET_HEIGHT) {
            setExpandable(true);
        }
    }, []);

    const descriptionClasses = [classes.Description];

    if (expandable && !showFullDescription) {
        descriptionClasses.push(classes.ClipDescription);
    }

    return (
        <div className={classes.ResourceCard}>
            <b className={classes.Title}>{title}</b>
            <span
                ref={resourceDescriptionRef}
                className={descriptionClasses.join(' ')}
            >
                {description}
                {
                    (expandable && !showFullDescription) &&
                    <div className={classes.ContentClip}>
                    </div>
                }
            </span>
            {
                expandable &&
                <button
                    className={classes.ExpandDescriptionButton}
                    onClick={(event) => {event.target.focus(); setShowFullDescription((showFull) => !showFull);}}
                    aria-label={`Read ${showFullDescription ? 'Less' : 'More'} button.  Use this button to display ${showFullDescription ? 'less' : 'more'} of the description for this resource.`}
                >
                    {`Read ${showFullDescription ? 'Less' : 'More'}`}
                </button>
            }
            <ul className={classes.ResourceLinkContainer}>
                {
                    pdfFileName &&
                    <li>
                        <a
                            target= "_blank"
                            rel="noopener noreferrer"
                            href={require(`../../resources/${userCategory}/${pdfFileName}`)}
                            aria-label={`use this link to open, in a new browser tab, the pdf version of ${title}`}
                        >
                            Open as PDF
                        </a>
                    </li>
                }
                {
                    docxFileName &&
                    <li>
                        <a
                            href={require(`../../resources/${userCategory}/${docxFileName}`)}
                            aria-label={`use this link to download the text document version of ${title}`}
                        >
                            Download as Text Document
                        </a>
                    </li>
                }
                {
                    resourceUrl &&
                    <li>
                        <a
                            target= "_blank"
                            rel="noopener noreferrer"
                            href={resourceUrl}
                            aria-label={`use this link to open ${title} in a new browser tab`}
                        >
                            Go to website
                        </a>
                    </li>
                }
            </ul>
        </div>
    );
}

export default ResourceCard;
