import React, { useContext, useState, useRef, useEffect } from 'react';

import ViewportModalContext from '../../context/ViewportModalContext';
import NotificationContext from '../../context/NotificationContext';

import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import Spinner from '../UI/Spinner/Spinner';
import axios from 'axios';
import classes from './ContactForm.module.css';

const ContactForm = ({
    introText,
    contactReason
}) => {
    const { displayViewportModal, closeViewportModal, rememberLastFocused } = useContext(ViewportModalContext);
    const { showNotification } = useContext(NotificationContext);
    const firstInputRef = useRef();
    const [contactForm, setContactForm] = useState({
        name: {
            fields: {
                firstName: {
                    elementType: 'text',
                    label: 'First Name',
                    spokenLabel: 'First name, please enter your first name',
                    // placeholder: 'Enter Your First Name',
                    valueType: 'text',
                    value: '',
                    autoComplete: 'given-name',
                    ref: firstInputRef,
                    validation: {
                        rules: [
                            {required: true, errorMessage: 'Required'}
                        ],
                        results: {
                            valid: false,
                            errorMsg: null
                        }
                    },
                    blurred: false
                },
                lastName: {
                    elementType: 'text',
                    label: 'Last Name',
                    spokenLabel: 'Last name, please enter your last name',
                    // placeholder: 'Enter Your Last Name',
                    valueType: 'text',
                    value: '',
                    autoComplete: 'family-name',
                    ref: null,
                    validation: {
                        rules: [
                            {required: true, errorMessage: 'Required'}
                        ],
                        results: {
                            valid: false,
                            errorMsg: null
                        }
                    },
                    blurred: false
                }
            }
        },
        emailAddress: {
            elementType: 'text',
            label: 'Email Address',
            spokenLabel: 'Email Address, please enter your email address',
            // placeholder: 'Enter Your Email Address',
            valueType: 'email',
            value: '',
            autoComplete: 'email',
            ref: null,
            validation: {
                rules: [
                    {required: true, errorMessage: 'Required'},
                    {format: 'emailAddress', errorMessage: 'Invalid Email Address'}
                ],
                results: {
                    valid: false,
                    errorMsg: null
                }
            },
            blurred: false
        },
        phoneNumber: {
            elementType: 'text',
            label: 'Phone Number',
            spokenLabel: 'Phone Number, please enter your phone number',
            // placeholder: '(XXX) XXX-XXXX',
            valueType: 'tel',
            value: '',
            autoComplete: 'tel-national',
            ref: null,
            validation: {
                rules: [
                    {required: true, errorMessage: 'Required'},
                    {format: 'phoneNumber', errorMessage: 'Invalid Phone Number'}
                ],
                results: {
                    valid: false,
                    errorMsg: null
                }
            },
            blurred: false
        },
        message: {
            elementType: 'textarea',
            label: 'How Can We Help?',
            rows: '7',
            spokenLabel: 'How Can We Help? please enter your message',
            // placeholder: 'Enter Your Message',
            valueType: 'text',
            value: '',
            autoComplete: null,
            ref: null,
            validation: {
                rules: [
                    {required: true, errorMessage: 'Required'}
                ],
                results: {
                    valid: false,
                    errorMsg: null
                }
            },
            blurred: false
        }
    });

    const [hasErrors, setHasErrors] = useState(true);

    const contactFormRef = useRef();
    const cancelButtonRef = useRef();

    useEffect(() => {
        firstInputRef.current.focus();
    }, []);

    useEffect(() => {
        const fieldValidityStatuses = [];
        const checkFormValidity = (config) => {
            Object.keys(config).forEach((configKey) => {
                const configItem = config[configKey];
                if (configItem.fields) {
                    checkFormValidity(configItem.fields);
                }
                else {
                    fieldValidityStatuses.push(configItem.validation.results.valid);
                }
            })
        }
        checkFormValidity(contactForm);
        const isFormInputValid = fieldValidityStatuses.filter((status) => status === false).length > 0;
        setHasErrors(isFormInputValid);
    }, [contactForm]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27 ) {
                event.preventDefault();
                closeViewportModal();
            }
            if (event.key === 'Tab' || event.keyCode === 9) {
                if (event.shiftKey) {
                    if (document.activeElement === contactFormRef.current) {
                        event.preventDefault();
                        cancelButtonRef.current.focus();
                    }
                }
                else if (document.activeElement === cancelButtonRef.current) {
                    event.preventDefault();
                    event.stopPropagation(); // need both preventDefault and stopPropagation ???
                    contactFormRef && contactFormRef.current.focus();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [contactFormRef, closeViewportModal]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const nameParts = event.target.name.split('.');
        const lastNamePart = nameParts.pop();

        const updatedContactForm = {
            ...contactForm
        };

        if (lastNamePart === 'emailAddress' && event.currentTarget.value.includes(' ')) {
            event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "");
        }
        let updatedValue = event.currentTarget.value;

        if (lastNamePart === 'phoneNumber') {
            updatedValue = formatPhoneNumber(updatedValue);
        }

        let pointer = updatedContactForm;
        nameParts.forEach((namePart) => {
            pointer[namePart] = {...pointer[namePart]};
            pointer = pointer[namePart].fields;
        });

        const errMsg = checkValidity(updatedValue, pointer[lastNamePart].validation.rules);
        let pointerValidation = {...pointer[lastNamePart].validation};
        let pointerValidationResults = {...pointerValidation.results};
        pointerValidation.results = {...pointerValidationResults, errorMsg: errMsg, valid: (errMsg === null ? true : false)};
        pointer[lastNamePart].validation = {...pointerValidation};
        pointer[lastNamePart] = {...pointer[lastNamePart], value: updatedValue, blurred: false};
        setContactForm(updatedContactForm);
    };

    const inputBlurredHandler = (event) => {
        const nameParts = event.target.name.split('.');
        const lastNamePart = nameParts.pop();
        const updatedContactForm = {
            ...contactForm
        };

        let pointer = updatedContactForm;
        nameParts.forEach((namePart) => {
            pointer[namePart] = {...pointer[namePart]};
            pointer = pointer[namePart].fields;
        });

        let updatedValue = event.target.value;
        if (event.target.value.startsWith(' ') || event.target.value.endsWith(' ')) {
            updatedValue = updatedValue.trim();
        }

        const errMsg = checkValidity(event.target.value, pointer[lastNamePart].validation.rules);
        let pointerValidation = {...pointer[lastNamePart].validation};
        let pointerValidationResults = {...pointerValidation.results};
        pointerValidation.results = {...pointerValidationResults, errorMsg: errMsg, valid: (errMsg === null ? true : false)};
        pointer[lastNamePart].validation = {...pointerValidation};
        pointer[lastNamePart] = {...pointer[lastNamePart], value: updatedValue, blurred: true};
        setContactForm(updatedContactForm);
    }

    const checkValidity = (value, rules) => {
        let errMsg = null;
        if (rules) {
            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i];
                if (rule.required && value === '') {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (rule.format === 'phoneNumber' && !validatePhoneNumber(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (rule.format === 'emailAddress' && !validateEmailAddress(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
            };
        }
        return errMsg;
    }

    const formatPhoneNumber = (value) => {
        value = value.trim();
        value = value.replace(/\D/g,'');
        if (value.length > 3 && value.length <= 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3);
        }
        else if (value.length > 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3,6) + "-" + value.slice(6);
        }
        return (value.length > 14) ? value.slice(0, -1) : value;
    }

    const validatePhoneNumber = (value) => {
        const phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
        const digits = value.replace(/\D/g, "");
        return phoneRe.test(digits);
    }

    const validateEmailAddress = (value) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value.toLowerCase());
    }

    const sendMessageHandler = (event) => {
        event.preventDefault();
        displayViewportModal(<Spinner message='Sending...' />);
        rememberLastFocused(event.target);

        const completedContactForm = {
            ...contactForm
        };

        let messageSubject;
        let introduction;
        if (contactReason === 'contactUs') {
            messageSubject = 'Contact Us --  The Patient Warrior and Me Contact Us Form';
            introduction = 'You have a new message via The Patient Warrior and Me Contact Us Form';
        }
        else if (contactReason === 'sponsor') {
            messageSubject = 'Becoming a Sponsor -- The Patient Warrior and Me Sponsor Interest Form';
            introduction = 'You have a new message via The Patient Warrior and Me Sponsor Interest Form';
        }

        const emailDetails = {
            'customerDetails': {
                name: completedContactForm['name'].fields['firstName'].value.concat(' ').concat(completedContactForm['name'].fields['lastName'].value),
                phoneNumber: completedContactForm['phoneNumber'].value,
                emailAddress: completedContactForm['emailAddress'].value
            },
            'messageDetails': {
                subject: messageSubject,
                messageIntro: introduction,
                message: completedContactForm['message'].value
            }
        };
        axios({
            method: 'POST',
            url: 'https://qry64sepy5.execute-api.us-west-2.amazonaws.com/v1/contact-us',
            data: emailDetails
        })
        .then( (response) => {
            closeViewportModal();
            if (response.status === 204) {
                showNotification('Your message was sent successfully', 'success');
            }
            else {
                showNotification('We\'re sorry but an error occurred while sending your message', 'error');
            }
        })
        .catch( (error) => {
            closeViewportModal();
            showNotification('We\'re sorry but an error occurred while sending your message', 'error');
        });
    }

    return (
        <>
            <div
                ref={contactFormRef}
                className={classes.ContactForm}
                tabIndex='0'
            >
                <button
                    className={classes.CloseButton}
                    onClick={() => closeViewportModal()}
                    aria-label='Contact form close button.  Use ths button to close this contact form.'
                >
                    <svg
                        version="1.1"
                        id="Capa_1"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 47.971 47.971"
                    >
                        <g>
                            <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                                c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                                C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                                s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg>
                </button>
                <b>{introText}</b>
                <b>Send us some basic info.  We will be in touch shortly!</b>
                <div>
                    <form
                        onSubmit={sendMessageHandler}
                    >
                        <div className={classes.Inputs}>
                        {
                            Object.keys(contactForm).map((formSectionKey, index) => {
                                const contactFormItemConfig = contactForm[formSectionKey];
                                if (contactFormItemConfig.fields) {
                                    return (
                                        <fieldset key={index}>
                                            {
                                                Object.keys(contactFormItemConfig.fields).map((fieldsetInputKey, index) => {
                                                    const fieldConfig = contactFormItemConfig.fields[fieldsetInputKey];
                                                    const fieldsetFieldKey = `${formSectionKey}.${fieldsetInputKey}`;
                                                    return  <Input
                                                                key={index}
                                                                id={fieldsetInputKey}
                                                                name={fieldsetFieldKey}
                                                                label={fieldConfig.label}
                                                                screenReaderText={fieldConfig.spokenLabel}
                                                                elementType={fieldConfig.elementType}
                                                                elementConfig={{
                                                                    type: fieldConfig.valueType,
                                                                    placeholder: (fieldConfig.placeholder ? fieldConfig.placeholder : ''),
                                                                    value: fieldConfig.value,
                                                                    autoComplete: fieldConfig.autoComplete,
                                                                }}
                                                                validationRules={fieldConfig.validation.rules}
                                                                errMessage={fieldConfig.blurred && fieldConfig.validation.results.errorMsg}
                                                                inputRef={fieldConfig.ref}
                                                                inputWidth={fieldConfig.inputWidth}
                                                                keyDown={(formSectionKey === 'emailAddress') ? (event) => {if (event.key === ' ') {event.preventDefault();}} : () => {}}
                                                                changed={(event) => inputChangedHandler(event, fieldsetFieldKey)}
                                                                blurred={(event) => inputBlurredHandler(event, fieldsetFieldKey)}
                                                            />;
                                                })
                                            }
                                        </fieldset>
                                    )
                                }
                                else {
                                    return  <Input
                                                key={index}
                                                id={formSectionKey}
                                                name={formSectionKey}
                                                label={contactFormItemConfig.label}
                                                screenReaderText={contactFormItemConfig.spokenLabel}
                                                elementType={contactFormItemConfig.elementType}
                                                elementConfig={{
                                                    type: contactFormItemConfig.valueType,
                                                    placeholder: (contactFormItemConfig.placeholder ? contactFormItemConfig.placeholder : ''),
                                                    value: contactFormItemConfig.value,
                                                    autoComplete: contactFormItemConfig.autoComplete,
                                                    rows: contactFormItemConfig.rows

                                                }}
                                                validationRules={contactFormItemConfig.validation.rules}
                                                errMessage={contactFormItemConfig.blurred && contactFormItemConfig.validation.results.errorMsg}
                                                inputRef={contactFormItemConfig.ref}
                                                inputWidth={contactFormItemConfig.inputWidth}
                                                keyDown={(formSectionKey === 'emailAddress') ? (event) => {if (event.key === ' ') {event.preventDefault();}} : () => {}}
                                                changed={(event) => inputChangedHandler(event, formSectionKey)}
                                                blurred={(event) => inputBlurredHandler(event, formSectionKey)}
                                            />;
                                }
                            })
                        }
                        </div>
                        <div className={classes.ButtonContainer}>
                            <Button
                                types={['contained', 'small']}
                                clicked={sendMessageHandler}
                                screenReaderText='Submit button.  Use this button to send us the contact info and message you entered on our contact form.'
                                interactive={!hasErrors}
                            >
                                Submit
                            </Button>
                            <Button
                                types={['contained', 'small']}
                                ref={cancelButtonRef}
                                clicked={closeViewportModal}
                                screenReaderText='Cancel button.  Use this button to clear the information you entered and close our contact form.'
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                    <img
                        className={classes.LogoImage}
                        src={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                        alt='The Patient Warrior and Me logo'
                    />
                </div>
            </div>
        </>
    );
}

export default ContactForm;
