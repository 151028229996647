import React, { useRef, useEffect, forwardRef } from 'react';

import NavigationItems from '../UI/NavigationItems/NavigationItems';

import classes from './SideDrawer.module.css';

import routeConfig from '../../data/routes';

const SideDrawer = forwardRef(({
    show,
    close,
    focusToggle
}, drawerToggleRef) => {
    useEffect(() => {
        const links = sideDrawerRef.current.getElementsByTagName('a');
        const lastLink = links[links.length - 1];

        const handleKeyDown = (event) => {
            if ( !event.keyCode || event.keyCode === 27 ) {
                event.preventDefault();
                close();
            }
            const isTabKeyPressed = event.key === 'Tab' || event.keyCode === 9;

            if (!isTabKeyPressed) {
                return;
            }
            else if (!event.shiftKey && document.activeElement === lastLink) {
                event.preventDefault();
                focusToggle();
            }
            else if (event.shiftKey && document.activeElement === drawerToggleRef.current) {
                event.preventDefault();
                lastLink.focus();
            }
        }

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [show, close, drawerToggleRef, focusToggle]);

    const sideDrawerClasses = [classes.SideDrawer];

    if (show) {
        sideDrawerClasses.push(classes.Show);
    }
    else {
        sideDrawerClasses.push(classes.Hide);
    }

    const sideDrawerRef = useRef();

    return (
        <div
            ref={sideDrawerRef}
            className={sideDrawerClasses.join(' ')}
            aria-hidden={!show}
        >
            <div id='mobile-nav-wrapper' className={[classes.NavigationWrapper, (show ? classes.ScaleUp : classes.ScaleDown)].join(' ')}>
                <nav>
                    <NavigationItems
                        routes={routeConfig.mainRoutes}
                        navItemClicked={close}
                        showNavItems={show}
                    />
                </nav>
            </div>
        </div>
    )
});

export default SideDrawer;
