import React, { forwardRef } from 'react';

import classes from './DrawerToggle.module.css';

const DrawerToggle = forwardRef(({
    sideDrawerOpen,
    clicked
}, drawerToggleRef) => {

    return (
        <button
            ref={drawerToggleRef}
            className={[classes.DrawerToggle, (sideDrawerOpen ? classes.ToggleOn : classes.ToggleOff)].join(' ')}
            onClick={clicked}
            aria-label='Website menu toggle button.  Use this button to open and close the website menu.'
        >
            <div className={classes.DivWrapper}>
                <span></span>
            </div>
        </button>
    )
});

export default DrawerToggle;
