import React, { useState, useEffect } from 'react';

import PageIntro from '../../components/PageIntro/PageIntro';

import partnersData from '../../data/partners.json';
import aboutData from '../../data/about.json';

import classes from './About.module.css';

const About = () => {
    const [partnerQuantityDisplaying, setPartnerQuantityDisplaying] = useState(4);

    useEffect(() => {
        console.log('* * * USEEFFECT');
    });

    const pageIntroContent = (
        <span className={classes.IntroContent}>
            {aboutData.pageIntro}
        </span>
    );

    return (
        <>
            <PageIntro
                headingText={aboutData.pageHeading}
                introContent={pageIntroContent}
                logoImageSrc={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                logoImageAlt='The Patient Warrior and Me logo'
            />
            <div className={classes.About}>
                <div className={classes.CompanyAbout}>
                    {
                        aboutData.sections.map((sectionConfig, index) => {
                            return (
                                <div key={index}>
                                    <h2>{sectionConfig.heading}</h2>
                                    {
                                        sectionConfig.paragraphs.map((paragraph, index) =>
                                            <p key={index}>{paragraph}</p>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.Bio}>
                    <h2>{aboutData.bio.heading}</h2>
                    <div className={classes.BioCard}>
                        <img
                            className={classes.BioHeadshot}
                            src={require('../../assets/images/team/jim-and-caryn-kommers.jpg')}
                            alt='Jim and Caryn Kommers'
                        />
                        <b>{aboutData.bio.name}</b>
                    </div>
                    {
                        aboutData.bio.content.map((contentItem, index) => {
                            if (contentItem.type === 'paragraph') {
                                return (<p key={index}>{contentItem.text}</p>);
                            }
                            else if (contentItem.type === 'multiTypeParagraph') {
                                return contentItem.content.map((multiTypeItem, index) => {
                                    if (multiTypeItem.text) {
                                        return (<span key={index}>{multiTypeItem.text}</span>);
                                    }
                                    else if (multiTypeItem.link) {
                                        return (
                                            <a
                                                key={index}
                                                href={multiTypeItem.link.url}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                {multiTypeItem.link.name}
                                            </a>
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                                });
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </div>
                <div className={classes.Sponsors}>
                    <h2>{aboutData.sponsors.heading}</h2>
                    {
                        aboutData.sponsors.content.map((multiTypeItem, index) => {
                            if (multiTypeItem.plain) {
                                return (<span key={index}>{multiTypeItem.plain}</span>);
                            }
                            else if (multiTypeItem.bold) {
                                return (
                                    <b
                                        key={index}
                                    >
                                        {multiTypeItem.bold}
                                    </b>
                                );
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </div>
                <div
                    className={classes.Partners}
                    id='partners'
                >
                    <h2>{aboutData.partners.heading}</h2>
                    <ul>
                        {
                            Object.values(partnersData).slice(0, partnerQuantityDisplaying).map((partner, index) => {
                                return (
                                    <li key={index}>
                                        <a
                                            className={classes.PartnerCard}
                                            href={partner.url}
                                            target= "_blank"
                                            rel="noopener noreferrer"
                                            aria-label={`Link to the company website for ${partner.name}.`}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/company logos/${partner.logoFileName}`}
                                                alt='Logo for Magnet Web Designs LLC'
                                            />
                                            <span>
                                                {partner.name}
                                            </span>
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    {
                        (partnerQuantityDisplaying < Object.keys(partnersData).length) &&
                        <button
                            onClick={(event) => {event.target.focus(); setPartnerQuantityDisplaying((partnerQuantityDisplaying) => partnerQuantityDisplaying + 4);}}
                            aria-label='Show more button.  Use this button to display information on more of our partners.'
                        >
                            Show More....
                        </button>
                    }
                </div>
            </div>
        </>
    );
}

// <i>{aboutData.bio.title}</i>

export default About;
