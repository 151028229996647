import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = ({
    path,
    clicked,
    currentRoute,
    focusable,
    children
}) => {
    return (
            <NavLink
                to={path}
                onClick={clicked}
                className={(navData) => navData.isActive ? [classes.NavigationItem, classes.active].join(' ') : classes.NavigationItem}
                aria-current={currentRoute ? 'page' : null}
                tabIndex={focusable ? null : '-1'}
            >
                {children}
            </NavLink>
    )
}

export default NavigationItem;
