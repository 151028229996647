import React, { useState } from 'react';

import PageIntro from '../../components/PageIntro/PageIntro';
import Button from '../../components/UI/Button/Button';
import PodcastEpisodeCard from '../../components/PodcastEpisodeCard/PodcastEpisodeCard';

import podcasts from '../../data/podcasts.json';
import podcastEpisodeData from '../../data/podcast-episodes.json';

import classes from './Podcasts.module.css';

const Podcasts = () => {
    const [episodeQuantityDisplaying, setEpisodeQuantityDisplaying] = useState(8);

    const PODCAST_EPISODE_QUANTITY_INCREMENT = 8;

    const pageIntroContent = (
        <>
            <span className={classes.IntroContent}>
                {podcasts.pageIntro}
            </span>
            <div className={classes.ChannelMention}>
                <span>
                    {podcasts.channelMention.textBeginning}
                    <a
                        href={podcasts.channelMention.linkUrl}
                        target= "_blank"
                        rel='noopener noreferrer'
                        aria-label='Link to The Patient Warrior and Me podcast channel on Spotify'
                    >
                        {podcasts.channelMention.linkText}
                    </a>
                    {podcasts.channelMention.textEnding}
                </span>
                <a
                    className={classes.SpotifyLink}
                    href='https://open.spotify.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Link to Spotify home page'
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/images/company logos/spotify-logo.png`}
                        alt='Spotify logo'
                    />
                </a>
            </div>
        </>
    );

    return (
        <>
            <PageIntro
                headingText={podcasts.pageHeading}
                introContent={pageIntroContent}
                logoImageSrc={`${process.env.PUBLIC_URL}/images/company logos/PWAM-logo.png`}
                logoImageAlt='The Patient Warrior and Me logo'
            />
            <div className={classes.EpisodesContainer}>
                <h2>{podcasts.episodes.heading}</h2>
                <ul className={classes.PodcastEpisodes}>
                    {
                        Object.keys(podcastEpisodeData).reverse().slice(0, episodeQuantityDisplaying).map((episodeKey, index) => {
                            const episodeDetails = podcastEpisodeData[episodeKey];
                            console.log(episodeDetails);
                            return (
                                <li key={index}>
                                    <PodcastEpisodeCard
                                        coverImageFileName={episodeDetails.imageFileName}
                                        runTime={episodeDetails.runtime}
                                        ordinalNumber={episodeKey}
                                        recordDate={episodeDetails.recordDate}
                                        title={episodeDetails.title}
                                        description={episodeDetails.description}
                                        spotifyUrl={episodeDetails.spotifyUrl}
                                        resources={episodeDetails.resources}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    (episodeQuantityDisplaying < Object.keys(podcastEpisodeData).length) &&
                    <Button
                        types={['large']}
                        clicked={() => setEpisodeQuantityDisplaying((episodeQuantityDisplaying) => episodeQuantityDisplaying + PODCAST_EPISODE_QUANTITY_INCREMENT)}
                        screenReaderText='Load more button.  Use this button to load more podcast episodes.'
                    >
                        {podcasts.episodes.buttonText}
                    </Button>
                }
            </div>
        </>
    );
}

export default Podcasts;
